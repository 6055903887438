<template>
  <aside>
    <!-- Tabs -->
    <div class="sticky top-0 z-10 hidden pt-4 bg-white dark:bg-neutral-800 sm:block">
      <div class="flex items-center border-b border-gray-200 dark:border-gray-700">
        <nav
          class="flex flex-1 -mb-px space-x-6 xl:space-x-8"
          aria-label="Tabs"
        >
          <button
            :class="[active === 'details' ? 'text-indigo-600 border-indigo-500' : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300']"
            class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap"
            @click.prevent="active = 'details'"
          >
            Details
          </button>
          <button
            :class="[active === 'comments' ? 'text-indigo-600 border-indigo-500' : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300']"
            class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap"
            @click.prevent="active = 'comments'"
          >
            Comments
          </button>
          <button
            :class="[active === 'actions' ? 'text-indigo-600 border-indigo-500' : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300']"
            class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap"
            @click.prevent="active = 'actions'"
          >
            Actions
          </button>
        </nav>
      </div>
    </div>

    <div class="flex w-full h-full">
      <div
        v-if="active === 'comments'"
        class="flex flex-col-reverse items-start justify-end flex-1 mx-4 my-8"
      >
        <SidebarComments :asset="asset" />
      </div>

      <div
        v-if="active === 'actions'"
        class="flex flex-col-reverse items-start justify-end flex-1 mx-4 my-8"
      >
        <SidebarActions :asset="asset" />
      </div>

      <div
        v-if="active === 'details'"
        class="flex flex-col-reverse items-start justify-end flex-1 mx-4 my-8"
      >
        <SidebarDetails :asset="asset" />
      </div>
    </div>
  </aside>
</template>
<script>
const SidebarComments = () => import('@components/viewer/sidebar/SidebarComments')
const SidebarActions = () => import('@components/viewer/sidebar/SidebarActions')
const SidebarDetails = () => import('@components/viewer/sidebar/SidebarDetails')

export default {

  components: {
    SidebarComments,
    SidebarActions,
    SidebarDetails
  },
  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      active: 'details'
    }
  }

}

</script>
